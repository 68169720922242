import $ from "jquery";
import "slick-carousel";
import Lenis from "@studio-freight/lenis";

// Helpers
function throttle(cb, delay) {
  let wait = false;

  return (...args) => {
    if (wait) {
      return;
    }

    cb(...args);
    wait = true;
    setTimeout(() => {
      wait = false;
    }, delay);
  };
}

// Sliders
$(".research-slider__slider").slick({
  arrows: true,
  dots: true,
  asNavFor: ".research-slider__taglines",
  autoplay: true,
  autoplaySpeed: 5000,
});

$(".research-slider__taglines").slick({
  arrows: false,
  dots: false,
  asNavFor: ".research-slider__slider",
});

$(".steps__slider").slick({
  arrows: true,
  dots: false,
});

// Smooth scroll link click
$(".header__flex a").on("click", function (event) {
  // target all links
  if (this.hash !== "") {
    event.preventDefault();
    const hash = this.hash;

    $("html, body").animate(
      {
        scrollTop: $(hash).offset().top,
      },
      800
    );
  }
});

// Smooth scrolling
const lenis = new Lenis();

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

// Header nav
$(window).on(
  "scroll",
  throttle(function () {
    setActiveNav();
  }, 100)
);

function getOffsetTop(element) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

function setActiveNav() {
  const scrollPos = $(window).scrollTop() + 250;
  const anchors = document.querySelectorAll(".header__flex a");
  const els = [];

  anchors.forEach((anchor) => {
    const section = document.querySelector(anchor.getAttribute("href"));
    els.push({ top: getOffsetTop(section), anchor });
  });

  els.forEach((el) => {
    if (el.top < scrollPos) {
      el.anchor.parentElement.classList.add("header__link--active");
    } else {
      el.anchor.parentElement.classList.remove("header__link--active");
    }
  });

  $(".header__link--last").removeClass("header__link--last");
  const actives = document.querySelectorAll(".header__link--active");
  const lastActive = actives[actives.length - 1];
  if (lastActive) lastActive.classList.add("header__link--last");
}

setActiveNav();

// map video
const video = document.querySelector(".overhead video");

const observer = new IntersectionObserver(function (entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      // Check if the video is in view
      video.play();
    } else {
      video.pause();
    }
  });
});

observer.observe(video);

document.addEventListener("DOMContentLoaded", function () {
  const colorDiv = document.querySelector(".color-fade-section__color");
  const breakthrough = document.querySelector(".breakthrough");
  const startColor = [57, 46, 135]; // RGB for #392e87
  const endColor = [218, 218, 237]; // RGB for #fff

  const delayFactor = 0.3; // Adjust this value as desired

  function setFade() {
    const breakthroughTop = breakthrough.getBoundingClientRect().top;
    const breakthroughHeight = breakthrough.offsetHeight;

    if (
      breakthroughTop >= window.innerHeight ||
      breakthroughTop + breakthroughHeight <= 0
    ) {
      // The .breakthrough element is not in view
      return;
    }

    // Calculate fade based on the relative position of the .breakthrough within the viewport
    let fadeRatio =
      1 -
      (breakthroughTop + breakthroughHeight) /
        (window.innerHeight + breakthroughHeight);

    // Adjust fadeRatio based on delay factor
    fadeRatio = (fadeRatio - delayFactor) / (1 - delayFactor);
    fadeRatio = Math.max(fadeRatio, 0); // Ensure it doesn't go negative

    const newColor = startColor.map((start, index) =>
      Math.round(start + fadeRatio * (endColor[index] - start))
    );
    colorDiv.style.backgroundColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
  }

  setFade(); // Set the fade on initial page load
  window.addEventListener("scroll", setFade); // Adjust fade on scroll
});

$(document).ready(function () {
  // For onload animations
  $("body").addClass("ready");

  // Scroll based animations
  var els = $(".on-scroll");

  function scrollAnimations() {
    // Scroll position
    var pos = $(window).scrollTop() + $(window).height();

    // fade in
    els.each(function (i) {
      var el = els.eq(i);
      var elPos = el.offset().top;

      if (pos > elPos) el.addClass("active");
    });
  }

  scrollAnimations();

  if (els.length) {
    $(window).on(
      "scroll",
      throttle(function () {
        scrollAnimations();
      }, 100)
    );
  }
});
